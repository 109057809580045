import { default as indexa6FRbvzuIYMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexZ3jUf0C6UFMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/faqs/index.vue?macro=true";
import { default as indexSACw01ps29Meta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/groups/index.vue?macro=true";
import { default as indexmQGG33KWSEMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/media/[id]/index.vue?macro=true";
import { default as index0yWPr4szsuMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/newsroom/index.vue?macro=true";
import { default as indexZgYDpGdkRlMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/products/[category]/[product]/index.vue?macro=true";
import { default as indexbDohKKK9UOMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/products/[category]/index.vue?macro=true";
import { default as indexrWLSLDqjUAMeta } from "/var/www/prod.exped.com/releases/202409091348/frontend/pages/sitemap/index.vue?macro=true";
export default [
  {
    name: indexa6FRbvzuIYMeta?.name ?? "slug",
    path: indexa6FRbvzuIYMeta?.path ?? "/:slug(.*)*",
    meta: indexa6FRbvzuIYMeta || {},
    alias: indexa6FRbvzuIYMeta?.alias || [],
    redirect: indexa6FRbvzuIYMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZ3jUf0C6UFMeta?.name ?? "faqs",
    path: indexZ3jUf0C6UFMeta?.path ?? "/faqs",
    meta: indexZ3jUf0C6UFMeta || {},
    alias: indexZ3jUf0C6UFMeta?.alias || [],
    redirect: indexZ3jUf0C6UFMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexSACw01ps29Meta?.name ?? "groups",
    path: indexSACw01ps29Meta?.path ?? "/groups",
    meta: indexSACw01ps29Meta || {},
    alias: indexSACw01ps29Meta?.alias || [],
    redirect: indexSACw01ps29Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQGG33KWSEMeta?.name ?? "media-id",
    path: indexmQGG33KWSEMeta?.path ?? "/media/:id()",
    meta: indexmQGG33KWSEMeta || {},
    alias: indexmQGG33KWSEMeta?.alias || [],
    redirect: indexmQGG33KWSEMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/media/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index0yWPr4szsuMeta?.name ?? "newsroom",
    path: index0yWPr4szsuMeta?.path ?? "/newsroom",
    meta: index0yWPr4szsuMeta || {},
    alias: index0yWPr4szsuMeta?.alias || [],
    redirect: index0yWPr4szsuMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: indexZgYDpGdkRlMeta?.name ?? "products-category-product",
    path: indexZgYDpGdkRlMeta?.path ?? "/products/:category()/:product()",
    meta: indexZgYDpGdkRlMeta || {},
    alias: indexZgYDpGdkRlMeta?.alias || [],
    redirect: indexZgYDpGdkRlMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/products/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbDohKKK9UOMeta?.name ?? "products-category",
    path: indexbDohKKK9UOMeta?.path ?? "/products/:category()",
    meta: indexbDohKKK9UOMeta || {},
    alias: indexbDohKKK9UOMeta?.alias || [],
    redirect: indexbDohKKK9UOMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/products/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrWLSLDqjUAMeta?.name ?? "sitemap",
    path: indexrWLSLDqjUAMeta?.path ?? "/sitemap",
    meta: indexrWLSLDqjUAMeta || {},
    alias: indexrWLSLDqjUAMeta?.alias || [],
    redirect: indexrWLSLDqjUAMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202409091348/frontend/pages/sitemap/index.vue").then(m => m.default || m)
  }
]